import { useUserStore } from '~/stores/user'
import navigateToHome from '~/helpers/navigateToHome'
import middlewareCheckContext from '~/helpers/middlewareCheckContext'

export default defineNuxtRouteMiddleware((to) => {
    if (useError().value || !middlewareCheckContext(to)) {
        return
    }

    const routeToName = to.name as string
    const userStore = useUserStore()

    const getLastPID = (): string => {
        const lastPID: string = useAppCookie('project').value || ''

        if (lastPID && userStore.projectIds.includes(+lastPID)) {
            return lastPID
        } else {
            return String(userStore.myProjectsIds[0] || '')
        }
    }

    // Перевірка авторизації
    if (!userStore.isAuthenticated) {
        return { name: 'language-auth-sign-in' }
    }

    // Перевірка закінчення реєстрації
    const isRouteToRegistrationStep: boolean = APP_REGISTRATION_STEP_ROUTE_NAMES.includes(routeToName)

    if (!userStore.isRegistrationCompleted) {
        const stepNumber = Number(routeToName[routeToName.length - 1])
            - (userStore.user.need_create_project ? 0 : 1)

        if (
            !isRouteToRegistrationStep
            || (stepNumber > userStore.user.signup_step)
        ) {
            return { name: 'language-auth-sign-up-step-' + userStore.user.signup_step }
        }

        return
    }

    if (isRouteToRegistrationStep) {
        return navigateToHome()
    }

    // Перевірка доступу до проєкту з URL
    if (
        to.params.pid
        && !userStore.projectIds.includes(+to.params.pid)
    ) {
        to.params.pid = getLastPID()

        return to
    }

    // Перевірка активності оператора в проєкті
    if (
        !userStore.isOperatorEnabled
        && (routeToName !== 'p-pid-inactive-operator')
        && !APP_INACTIVE_OPERATOR_AVAILABLE_AUTH_ROUTE_NAMES.includes(routeToName)
    ) {
        return {
            name: 'p-pid-inactive-operator',
            params: {
                pid: getLastPID(),
            },
        }
    }
})
